/* src/components/ProfileUpdate/ProfileUpdate.css */

.profile-update-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .profile-update-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-update-form .form-group {
    margin-bottom: 15px;
  }
  
  .profile-update-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .profile-update-form input[type="text"],
  .profile-update-form input[type="file"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .image-preview {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .image-preview img {
    max-width: 150px;
    height: auto;
    border-radius: 50%;
    border: 2px solid #1a3a5b;
  }
  
  .update-button {
    width: 100%;
    padding: 10px;
    background-color: #1a3a5b;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #163a5b;
  }
  
  .update-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  