
.ImageSlider__container { 
    display: flex;
    height: 73vh;
    width: 80%;
    margin: 2rem auto;
    justify-content: center;
    align-items: center;
    z-index: 0;
    box-shadow: 0 2px 8px #0000001a;


}

.ImageSlider__container-Image { 
    height: 100%;
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    display: flex;
    object-fit: cover;
    position: relative;
    /* border: 2px solid lightgray; */


}

.ImageSlider__container-Image img { 
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.leftArrow { 
    display: flex;
    position: absolute;
    top: 50%;
    background-color: rgba(255, 255, 255,0.6);
    transform: translate(0, 50%);
    left: 32px;
    padding: .5rem 1rem;
    border: white;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    cursor: pointer;
    /* display: none !important; */

}


.rightArrow { 
    display: flex;
    position: absolute;
    top: 50%;
    background-color: rgba(255, 255, 255,0.6);
    transform: translate(0, 50%);
    right: 32px;
    padding: .5rem 1rem;
    border: white;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    cursor: pointer;
    /* display: none !important; */

}



.NewInfo { 
    display: flex;
    width: 100%;
    height: fit-content;
    min-height: 70px;
    max-height: 100px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    /* left: 10%; */
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.626);
    color: white;
    text-decoration: none;
    border-radius: none;

}

.NewInfo h1  { 
    margin: 1rem;
    font-size: 30px;
}

.NewInfo p { 
    margin: 0rem 1rem 1rem 1rem;
    font-size: 18px;
}

@media screen and (max-width: 767px) { 

    .ImageSlider__container { 
        height: 40vh;
        width: 90%;
        margin: 0 auto;
        /* margin-top: 2%; */
        
    }

    .rightArrow { 
        right: 15px;
        opacity: 1 !important;
        top: 45% !important;
        background-color: rgba(255, 255, 255,0.4);
    }
    .leftArrow { 
        left: 15px;
        top: 45% !important;
        opacity: 1 !important;
        background-color: rgba(255, 255, 255,0.4);
    }

    .NewInfo { 
        width: 90%;
        left: 5%;
        min-height: 30% !important;
        height: fit-content !important;
        max-height: 40%;
    }

    .NewInfo p { 
        font-size: 16px;
    }

    .NewInfo h1 { 
        font-size: 20px;
        text-decoration: underline;
    }

    



}

@media screen and  (min-width: 768px) and (max-width: 991px) { 
    .ImageSlider__container { 
        height: 40vh;
    }
    .rightArrow { 
        opacity: 1 !important;
    }
    .leftArrow { 
        opacity: 1 !important;
    }

    .NewInfo { 
        width: 95%;
        left: 2.5%;
        
    }

    .NewInfo p { 
        font-size: 15px;
    }

    .NewInfo h1 { 
        font-size: 30px;
    }


}