/* ProblemOfTheWeek.css */

/* Učitavanje Google Fontova */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Poppins:wght@400;600&display=swap');

/* Definicija CSS varijabli za boje */
:root {
  --primary-color: #1a3a5b; /* Glavna boja za dugmad i linkove */
  --primary-hover: #13314f; /* Tamnija nijansa za hover */
  --secondary-color: #f0f0f0; /* Sekundarna boja za pozadinu */
  --text-color: #333333; /* Boja teksta */
  --link-color: #1a3a5b; /* Boja linkova */
  --link-hover-color: #13314f; /* Boja linkova na hover */
  --button-text-color: #ffffff; /* Boja teksta na dugmadima */
  --button-background: #1a3a5b; /* Pozadina dugmadi */
  --button-hover-background: #13314f; /* Pozadina dugmadi na hover */
  --table-header-background: var(--secondary-color);
  --border-color: #ddd; /* Boja okvira */
}

/* Osnovni stilovi za kontejner */
.ProblemOfTheWeek__container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px 20px;
  background-color: #ffffff;
  /* Uklonjen border-radius */
  /* border-radius: 12px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ProblemOfTheWeek__container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Naslov */
.ProblemOfTheWeek__title {
  font-family: 'Baloo 2', cursive;
  font-size: 2.5em;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

/* Sadržaj */
.ProblemOfTheWeek__content {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  color: #555555;
  margin-bottom: 30px;
}

.ProblemOfTheWeekParagraph { 
  display: flex;
  min-height: 40vh;
  justify-content: center;
  align-items: center;
} 

.ProblemOfTheWeekParagraph a { 
  margin: 0 .3rem !important; 
}

.text-align__center { 
  text-align: center;
}

.ProblemOfTheWeek__problem-file { 
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center; 

}

.ProblemOfTheWeek__link {
  display: inline-block;
  padding: 10px 20px;
  color: rgb(255, 142, 0);
  text-decoration: underline;
  transition: background-color 0.3s ease;
}


/* Sekcija za rješenje */
.ProblemOfTheWeek__solution-section {
  margin-top: 20px;
  text-align: center;
}

/* Link za login */
.ProblemOfTheWeek__login-link {
  color: var(--primary-color); /* #1a3a5b */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.ProblemOfTheWeek__login-link:hover {
  color: var(--primary-hover); /* #13314f */
}

/* Stilovi za učitavanje, greške i bez problema */
.ProblemOfTheWeek__loading, 
.ProblemOfTheWeek__error, 
.ProblemOfTheWeek__no-problem {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px 20px;
  background-color: #ffffff;
  /* Uklonjen border-radius */
  /* border-radius: 12px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #555555;
}

/* Spinner */
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--primary-color); /* #1a3a5b */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsivni dizajn */
@media (max-width: 600px) {
  .ProblemOfTheWeek__container {
    padding: 20px 15px;
    margin: 20px 10px;
  }

  .ProblemOfTheWeek__title {
    font-size: 2em;
  }

  .ProblemOfTheWeek__link {
    padding: 8px 16px;
    font-size: 1em;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border-width: 6px;
  }
}
