/* ProblemList.css */

/* Učitavanje Google Fontova */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Poppins:wght@400;600&display=swap');

/* Osnovni stilovi za kontejner */
.ProblemList__container {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: 'Poppins', sans-serif;
}

/* Naslov */
.ProblemList__title { 
  margin: 1rem 0;
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Baloo 2', cursive;
  color: #1a3a5b; /* Tamno plava boja sa vašeg sajta */
  text-align: center;
}

/* Stilizacija tabele */
.problem-list-table {
  width: 100%;
  margin: 1rem auto;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.problem-list-table thead {
  background-color: #f0f0f0;
}

.problem-list-table th, 
.problem-list-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 1em;
}

.problem-list-table th {
  font-weight: 600;
  text-transform: uppercase;
  color: #333333;
}

/* Stilizacija redova tabele */
.problem-list-table tbody tr {
  transition: background-color 0.3s;
}

.problem-list-table tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.problem-list-table tbody tr:hover {
  background-color: rgba(244, 138, 7, 0.1);
}

/* Stilizacija linkova u tabeli */
.problem-list-table td a.problem-link {
  color: #f48a07 !important;
  text-decoration: none;
  transition: color 0.3s;
}

.problem-list-table td a.problem-link:hover {
  color: #f48a00 !important;
}

/* Stil za poruke kada rešenje nije dostupno */
.no-solution {
  color: #888;
  font-style: italic;
}

/* Stilovi za učitavanje */
.ProblemList__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  font-family: 'Poppins', sans-serif;
  color: #333333;
}

/* Spinner */
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #f48a07;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Stilovi za paginaciju */
.pagination-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.pagination-button {
  border: none;
  background-color: #1a3a5b;
  color: #ffffff;
  padding: 6px 12px;
  margin: 5px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button:hover {
  background-color: #13314f;
}

.pagination-button.active {
  background-color: #1a3a5b;
  color: #ffffff;
}

.pagination-dots {
  display: inline-block;
  width: 30px;
  text-align: center;
  font-size: 1em;
  color: #333333;
}

/* Onemogućena dugmad */
.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} 

.text-align__center { 
  text-align: center;
  width: 60%;
  margin: auto;
}

/* Responsivni dizajn */
@media (max-width: 768px) {
  .problem-list-table th, 
  .problem-list-table td {
    padding: 8px 10px;
    font-size: 0.9em;
  }

  .ProblemList__title {
    font-size: 1.5em;
    margin: auto;
  }

  .pagination-button {
    padding: 6px 10px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .problem-list-table {
    width: 100%;
    table-layout: fixed;
  }

  .problem-list-table th,
  .problem-list-table td {
    padding: 8px 5px;
    font-size: 0.85em;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: hidden;
    width: 25%; /* Svaka ćelija zauzima 25% širine */
    box-sizing: border-box;
  }

  .problem-list-table th {
    white-space: normal; /* Promenili smo sa nowrap na normal */
    text-align: left; /* Poravnanje teksta levo */
  }

  .problem-list-table td {
    height: fit-content;
  }

  .text-align__center { 
    text-align: center;
    width: 100%;
  }
}

