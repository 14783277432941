/* SolutionForm.css */

/* Učitavanje Google Fontova */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Poppins:wght@400;600&display=swap');

/* Kontejner za formu */
.SolutionForm__container {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

/* Naslov forme */
.SolutionForm__title {
  font-family: 'Baloo 2', cursive;
  font-size: 1.8em;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
}

/* Forma */
.SolutionForm__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Polje forme */
.SolutionForm__field {
  display: flex;
  flex-direction: column;
}

/* Labela */
.SolutionForm__label {
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  color: #555555;
  margin-bottom: 5px;
}

/* Input */
.SolutionForm__input {
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  border: 1px solid #cccccc;
  border-radius: 6px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.SolutionForm__input:focus {
  border-color: #C67006;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  outline: none;
}

/* Dugme */
.SolutionForm__button {
  align-self: flex-start;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(244, 138, 7);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SolutionForm__button:hover {
  background-color: #C67006;
}

/* Poruka */
.SolutionForm__message {
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
  color: rgb(244, 138, 7);
  text-align: center;
}

/* Animacija fadeIn */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsivni dizajn */
@media (max-width: 600px) {
  .SolutionForm__container {
    padding: 15px 10px;
    margin: 20px 10px;
  }

  .SolutionForm__title {
    font-size: 1.5em;
  }

  .SolutionForm__input {
    padding: 8px;
    font-size: 0.9em;
  }

  .SolutionForm__button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
