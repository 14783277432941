/* SideMenu.css */
.side-menu {
    height: 100vh;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #1a3a5b;
    overflow-x: hidden;
    transition: 0.2s;
    padding-top: 60px;
    z-index: 999;
  }
  
  .side-menu.open {
    width: 250px;
  }
  
  .side-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .side-menu ul li {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #ffffff;
    display: block;
    text-align: center;
    transition: all ease-in-out 0.2s;
    width: 100% !important;

  }
  
  .side-menu ul li:hover {
    background-color: #e0effd;
  }
  
  .side-menu ul li a {
    color: #ffffff;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
  
  } 

  #special__link { 
    color: #ffffff;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    width: 100% !important;
    display: block;
    text-align: center;
    padding: 1rem 0;
  } 
  
  .side-menu ul li a:hover {
    text-decoration: none;
    color: #e0effd;
  }
  
  .menu-icon {
    font-size: 1.5rem;
    color: #1a3a5b;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
  }
  
  .close-icon {
    font-size: 1.5rem;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    display: none;
  }
  
  .side-menu.open + .close-icon {
    display: block;
  }
  
  .side-menu.open ~ .menu-icon {
    display: none;
  }
  

  .tapered-line {
    width: 100%;
    height: 1px; /* Adjust the height as needed */
    background: linear-gradient(to right, transparent 0%, rgb(252, 252, 252) 50%, transparent 100%);
  }
  

  .side-menu__img { 
    width: 100%;
    margin: 2rem auto !important;
    margin-bottom: 0rem !important;
    scale: .7;
    transition: all ease-in-out 0.3s;
  } 

  .side-menu #side-menu__text { 
    width: 100%;
    color: rgb(255, 142, 0);
    position: relative; 
    top: -75px !important;
    text-align: center;
    transition: all ease-in-out 0.3s;
    text-transform: uppercase;
    font-weight: bold;
  }


  /* SideMenu.css */

/* ... vaš postojeći CSS kod ... */

body.menu-open {
  overflow: hidden;
}
