/* Footer.css */
.footer {
    background-color: #1a3a5b;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
    width: 100%;
    /* height: 40vh;  */
    display: flex; 
    align-items: center;
    flex-direction: column;
    justify-content: center;
    bottom: 0;

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* max-width: 1200px; */
    margin: 0rem auto;
    padding: 0 20px;
    flex-wrap: wrap;
    align-items: center;
    /* /* border-bottom: 1px solid white; */
    border-bottom: .5px solid rgb(255, 255, 255, .5); 
  }
  
  .footer-section {
    flex: 1;
    min-width: 150px;
    margin: 10px;
    min-height: 105px !important;

  } 

  .footer-div { 
    display: flex !important;
    flex-direction: column !important;
    min-height: 98.5px !important;
    padding: 0;
    margin: 0;

  }
  
  .footer-logo {
    width: 150px;
  }
  
  .footer-section h4 {
    color: rgb(255, 142, 0);
  } 


  .footer-section .sigma__text { 
    position: relative; 
    top: -30px;
    text-transform: uppercase;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    margin: 5px 0;
  }
  
  .footer-section a {
    color: white;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer-section form {
    display: flex;
    margin-top: 10px;
  }
  
  .footer-section input {
    padding: 5px;
    border: none;
    border-radius: 3px 0 0 3px;
  }
  
  .footer-section button {
    padding: 5px;
    background-color: rgb(255, 142, 0);
    border: none;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
  }
  
  .footer-bottom {
    margin-top: 20px;
    padding-top: 10px;
  }
  
  .subscribe input {
    flex: 1;
  }
  
  .subscribe button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .subscribe i {
    margin-left: 5px;
    color: white;
  }
  
  .footer-section .fab {
    font-size: 1.5em;
    margin-top: 10px;
    color: white;
  }
  
  .footer-section .fab:hover {
    color: rgb(255, 142, 0);
  }


address { 
    color: white;
  }

address strong { 
    color: white;
    text-decoration: none;
  } 

.footer-bottom p { 
    color: white;
    border: none;
} 


@media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) { 

  .footer { 
    height: fit-content;
    
  }  

  .footer-container { 
    flex-direction: column;
    height: fit-content;
  }

  .footer-section { 
    margin: .5rem auto;
  }

  .footer-section a { 
    text-transform: none;
  } 

  .footer-logo { 
    width: 30% !important;
    margin-bottom: 1rem; 
  
  } 

  .footer-bottom { 
    width: 90%; 
    margin: 0 auto;
  }
}