.AboutPage__container { 
    width: 90%; 
    /* min-height: 100vh; */
    height: fit-content;
    margin: 0rem auto;
    /* margin-top: 120px;  */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    
} 

.AboutPage__container1 { 
    width: 100%; 
    /* min-height: 100vh; */
    height: fit-content;
    margin: 0rem auto;
    /* margin-top: 120px;  */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.AboutPage__container-left { 
    display: flex;
    width: 20%;
    min-height: 50vh;
    height: fit-content;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-left: 2rem;
    flex-direction: column;

} 

.AboutPage__PageList-container { 
    height: fit-content;
    margin: 1rem 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.AboutPage__container-right { 
    display: flex;
    width: 70%;
    min-height: 50vh;
    height: fit-content;
    margin-top: 3rem;
} 


.AboutPage__section { 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    
} 

.AboutPage__section-heading { 
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase !important;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 8px solid #1a3a5b;
    margin-bottom: 1rem;
} 

.AboutPage__section-infoText { 
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 25px;
    height: fit-content;
    margin-bottom: 2rem;
} 

.AboutPage__section-infoText h2 { 
    margin: 1rem 0;
} 

.AboutPage__section-infoText p { 
    margin: .5rem 0;
} 

.AboutPage__InstructorCard-container { 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 2rem 0;
    min-height: 300px;
    height: fit-content;

} 

.AboutPage__InstructorCard-textContainer { 
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    
}  

.AboutPage__InstructorCard-textContainerSpecial { 
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
    line-height: 25px;
    margin: 1rem 0;
} 



.AboutPage__InstructorCard-textContainer p  { 
    width: 70%;
    margin: 0;
    line-height: 25px;
    padding: .2rem 0;
    text-align: justify;
} 

.AboutPage__InstructorCard-container h3 { 
    font-size: 20px;
    text-transform: uppercase;  
    width: 100%;
    margin: 0;
    margin-bottom: .5rem;
}  


.AboutPage__InstructorCard-img { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background-color: rebeccapurple;
    width: 250px;
    max-width: 220px;
    max-height: 250px;
    object-fit: cover;
    object-position: top center;
} 


.AboutPage__InstructorCard-circleContainer { 
    width: 200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem;
    

}

.AboutPage__InstructorCard-imgCircle { 
    border-radius: 50%;
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    object-position: center top;
    margin: .5rem 0;
    display: flex;
    cursor: pointer; 
}


.AboutPage__InstructorCard-imgsquare { 
    width: fit-content;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center top;
    margin: .5rem 0;
    display: flex;
}

.AboutPage__section-infoText2 { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    line-height: 25px;
    height: fit-content;
}  


.contact-logo { 
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
} 

.contact-logo h4 { 
    color: rgb(255, 142, 0);
    text-transform: uppercase;
}

.contact-details { 
    display: flex;
    /* margin: .7rem; */
    flex-direction: column;
}  
.contact-details a { 
    color: black;
    text-transform: lowercase;
}

.contact-map { 
    display: flex;
    /* margin: .7rem; */
    flex-direction: column;
}   

.contact-map iframe { 
    border: none;
}

.contact-container { 
    display: flex;
    width: 100%;
    flex-direction: column;
    text-transform: uppercase;
    margin-bottom: 2rem;
} 

.contact-container p, .contact-container  h2 { 
    margin: .5rem 0;
    text-transform: uppercase;
} 

.contact-container p { 
    text-transform: uppercase;
    color: black;
}

 

@media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) { 

    .AboutPage__container1 { 
        width: 100% !important;
        flex-direction: column;
        padding: 0;
    
    }
    .AboutPage__container { 
        width: 100%;
        flex-direction: column;
        margin: 0;
        min-height: 100svh; 
        min-height: -webkit-fill-available;
    }  

    .AboutPage__section { 
        margin-bottom: 1rem auto;
        margin-top: 0;
    }

    .AboutPage__container-left { 
        width: 100%;
        height: fit-content !important;
        min-height: fit-content;
        margin: 3rem 0;
        margin-bottom: 0 !important;

    }  

    .AboutPage__PageList-container { 
        width: 90%;
        margin: 1rem auto;
        height: fit-content;
    } 

    .AboutPage__InstructorCard-container h3 { 
        width: 90%; 
        margin: .5rem auto;
    }

    .AboutPage__container-right { 
        width: 100%;
        margin-top: 0;
    } 

    .AboutPage__InstructorCard-textContainer { 
        width: 90%;
        margin: .5rem auto;
        flex-direction: column-reverse; 
    } 
    .AboutPage__InstructorCard-textContainer p { 
        width: 100%;
        margin: .5rem auto;
    } 
    
    .AboutPage__InstructorCard-img { 
        width: 100%;
        
    }

    .AboutPage__InstructorCard-textContainerSpecial { 
        width: 90%; 
        margin: 0rem auto;
        text-align: center;
    } 

    .AboutPage__InstructorCard-circleContainer { 
        text-align: center;
        width: fit-content;
    }

    .AboutPage__section-infoText2 { 
        width: 90%;
        margin: .5rem auto;
    }

    .AboutPage__InstructorCard-circleContainer { 
        width: 65% !important;
        margin: 1rem 0;
    } 
    .AboutPage__InstructorCard-circleContainer img { 
        width: 100%;
        object-fit: cover; 
    }   

    .contact-map { 
        width: 100%;
    } 

    .contact-map iframe { 
        width: 90% !important;
        margin: 1rem auto !important;
    }

    .page_toggle { 
        display: none;
    }

}