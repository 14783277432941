

.OrangeHeader__container { 
    display: flex; 
    width: 100%;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: rgb(255, 142, 0);

} 

.OrangeHeader__container a { 
    width: 45%;
    margin: 0 auto;
    text-decoration: none; 
    text-transform: uppercase; 
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 800;
    text-shadow: 2px;
    font-size: 15px;
}


@media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) { 
    .OrangeHeader__container { 
        display: none;
    }
}