/* PopUp.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #ffffff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    position: relative;
    overflow-y: auto;
    max-height: 80vh;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-content h2 {
    margin-top: 0;
    color: #1a3a5b;
  }
  
  .popup-content p {
    color: #333;
  }
  
  .popup-content ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #333;
  }
  
  @media (max-width: 480px) {
    .popup-content {
      width: 95%;
      padding: 15px;
    }
    .popup-close {
      top: 5px;
      right: 10px;
      font-size: 20px;
    }
  }
  