/* ArticleList.css */

/* Učitavanje Google Fontova */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Definicija CSS varijabli za boje */
:root {
  --primary-color: #1a3a5b; /* Glavna boja za dugmad i linkove */
  --primary-hover: #13314f; /* Tamnija nijansa za hover */
  --secondary-color: #f0f0f0; /* Sekundarna boja za pozadinu */
  --text-color: #333333; /* Boja teksta */
  --link-color: #1a3a5b; /* Boja linkova */
  --link-hover-color: #13314f; /* Boja linkova na hover */
  --button-text-color: #ffffff; /* Boja teksta na dugmadima */
  --button-background: #1a3a5b; /* Pozadina dugmadi */
  --button-hover-background: #13314f; /* Pozadina dugmadi na hover */
  --error-color: #ff8e00; /* Boja za poruke o grešci */
  --success-color: #28a745; /* Boja za poruke o uspehu */
  --border-color: #ddd; /* Boja okvira */
  --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --card-hover-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Osnovni stilovi za kontejner */
.ArticleList__container { 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-color: white;
  font-family: 'Poppins', sans-serif;
}

/* Grid za članke */
.ArticleList__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
  background-color: white;
}

/* Stilizacija kartica */
.ArticleList__card { 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #ffffff;
  text-decoration: none;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 1rem;
  box-shadow: var(--card-shadow);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.ArticleList__card:hover { 
  box-shadow: var(--card-hover-shadow);
  transform: translateY(-5px);
}

/* Stilizacija slike */
.ArticleList__image { 
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
}

/* Stilizacija naslova */
.ArticleList__title { 
  font-size: 1.5rem;
  color: var(--primary-color);
  text-align: center;
}

/* Stil za poruke */
.ArticleList__error, .ArticleList__loading {
  font-size: 1.2rem;
  color: var(--error-color);
  text-align: center;
  padding: 2rem;
}

/* Paginacija */
.ArticleList__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}

.ArticleList__button {
  padding: 0.5rem 1rem;
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.ArticleList__button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.ArticleList__button:hover:not(:disabled) {
  background-color: var(--button-hover-background);
}

.ArticleList__page-info {
  font-size: 1rem;
  color: var(--text-color);
}

.ArticleList__all-link {
  margin-top: 1.5rem;
}

.ArticleList__all-link-button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.ArticleList__all-link-button:hover {
  background-color: var(--primary-hover);
}

.text-align__center-blog { 
  text-align: center;
  display: block;
  margin: 0rem 0 2rem 0 !important;
  width: 50%;
}

/* Responsivni dizajn */
@media (max-width: 1024px) {
  .ArticleList__card { 
    width: 45%;
  }
}

@media (max-width: 768px) {
  .ArticleList__card { 
    width: 60%;
  }
}

@media (max-width: 480px) {
  .ArticleList__card { 
    width: 90%;
  }

  .ArticleList__title { 
    font-size: 1.2rem;
  }

  .ArticleList__image { 
    height: 150px;
  }

  .ArticleList__pagination {
    flex-direction: column;
    gap: 0.5rem;
  }

  .ArticleList__page-info {
    font-size: 0.9rem;
  }

  .text-align__center-blog { 
    width: 90%;

  }
}
