/* PasswordResetRequest.css */

/* Učitavanje Google Fontova */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Definicija CSS varijabli za boje */
:root {
  --primary-color: #1a3a5b; /* Glavna boja za dugmad i linkove */
  --primary-hover: #13314f; /* Tamnija nijansa za hover */
  --secondary-color: #f0f0f0; /* Sekundarna boja za pozadinu */
  --text-color: #333333; /* Boja teksta */
  --link-color: #1a3a5b; /* Boja linkova */
  --link-hover-color: #13314f; /* Boja linkova na hover */
  --button-text-color: #ffffff; /* Boja teksta na dugmadima */
  --button-background: #1a3a5b; /* Pozadina dugmadi */
  --button-hover-background: #13314f; /* Pozadina dugmadi na hover */
  --error-color: #ff8e00; /* Boja za poruke o grešci */
  --success-color: #28a745; /* Boja za poruke o uspehu */
  --border-color: #ddd; /* Boja okvira */
}

/* Osnovni stilovi za kontejner */
.PasswordResetRequest__container { 
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  background-color: var(--secondary-color);
  padding: 2rem 1rem;
  font-family: 'Poppins', sans-serif;
}

/* Stilizacija forme */
.PasswordResetRequest__form { 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Stilizacija labela */
.PasswordResetRequest__label { 
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 16px;
  color: var(--text-color);
}

/* Stilizacija input polja */
.PasswordResetRequest__label input { 
  width: 90%;
  height: 40px;
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  margin-top: 0.5rem;
  color: gray;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.PasswordResetRequest__label input:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Stilizacija dugmadi */
.PasswordResetRequest__button { 
  width: 100%;
  max-width: 200px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  color: var(--button-text-color);
  background-color: var(--button-background);
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.PasswordResetRequest__button:hover {
  background-color: var(--button-hover-background);
}

/* Stil za poruke */
.PasswordResetRequest__message { 
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  border: 1px solid;
  border-radius: 0; /* Uklonjen border-radius */
  width: 100%;
  max-width: 400px;
}

.PasswordResetRequest__message.success { 
  color: var(--success-color);
  border-color: var(--success-color);
}

.PasswordResetRequest__message.error { 
  color: var(--error-color);
  border-color: var(--error-color);
}

/* Responsivni dizajn */
@media (max-width: 600px) {
  .PasswordResetRequest__form { 
    padding: 1.5rem;
    width: 90%;
  }

  .PasswordResetRequest__label { 
    font-size: 14px;
  }

  .PasswordResetRequest__label input { 
    font-size: 14px;
    width: 100%;
  }

  .PasswordResetRequest__button { 
    font-size: 14px;
    padding: 0.5rem 0;
  }

  .PasswordResetRequest__message { 
    font-size: 14px;
  }
}
